import React from 'react';
import './App.scss';
// import Home from '../Home/Home';
import { Container } from '@material-ui/core';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  gtmId: 'AW-11015264208',
  events: {
      sendUserInfo: 'Home'
  }
}
TagManager.initialize(tagManagerArgs)

const Home = React.lazy(() => import('../Home/Home')) 
const App = () => {  
    return (
      <div className="App">
        <div id="header-top"></div>
        <Container maxWidth={false} disableGutters>
          <Home />
        </Container>            
      </div>
    ); 
}

export default App;
